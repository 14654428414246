import Avatar from "@material-ui/core/Avatar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import PersonIcon from "@material-ui/icons/Person";
import { useState } from "react";
import { changeAppState } from "../../../../../common/appState";
import { useUser } from "../../../../../common/hooks/useUser";
import { spaceBetween } from "../../../../../common/util/Array/spaceBetween";
import ShurikenProgress from "../../../../shared/Animations/ShurikenProgress";
import { Tooltip } from "../../../../shared/Tooltip";
import { User } from "../../../../shared/User/types";
import { zIndex } from "../../zIndex";

export function LoginIcon({
    containerStyle,
    isOpenHamburger,
    transitionClass,
}: {
    containerStyle?: BaseCSSProperties;
    isOpenHamburger?: boolean;
    transitionClass: "t500ms" | "t1s";
}) {
    const { user, isUserFetchDone } = useUser();
    const [isTooltipOpened, setTooltipOpened] = useState(false);

    const c = useLoginIconStyles({
        containerStyle,
    });

    return (
        <Tooltip
            title={user?.name || "Sign up"}
            placement="bottom"
            className={c.tooltip}
            onOpen={() => {
                setTooltipOpened(true);
            }}
            onClose={() => {
                setTooltipOpened(false);
            }}
        >
            <div
                className={spaceBetween(c.container, transitionClass)}
                onClick={() => {
                    if (!user) {
                        changeAppState("signInPanelState", { type: "signUp" });
                        return;
                    }
                    // if (shouldShowAlertIcon) {
                    //     openInitialPanelForUser(user, true);
                    //     return;
                    // }
                    changeAppState("signInPanelState", {
                        type: "myPageTop",
                    });
                }}
            >
                {/* {shouldShowAlertIcon && !isOpenHamburger && (
                    <AlertIcon
                        style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                            zIndex: 1,
                        }}
                        size={22}
                    />
                )} */}

                {isUserFetchDone ? (
                    <IconAndLevel
                        user={user}
                        isOpenHamburger={isOpenHamburger}
                        transitionClass={transitionClass}
                        isTooltipOpened={isTooltipOpened}
                    />
                ) : (
                    <ShurikenProgress size={40} />
                )}
            </div>
        </Tooltip>
    );
}
const useLoginIconStyles = makeStyles<
    Theme,
    { containerStyle?: BaseCSSProperties }
>({
    tooltip: { zIndex: zIndex.maxValue },
    container: ({ containerStyle }) => ({
        display: "flex",
        flexDirection: "column",
        position: "relative",
        ...containerStyle,
    }),
});

function IconAndLevel({
    user,
    isOpenHamburger,
    transitionClass,
    isTooltipOpened,
}: {
    user?: User;
    isOpenHamburger?: boolean;
    transitionClass: string;
    isTooltipOpened: boolean;
}) {
    const c = useLevelStyles();

    return (
        <>
            <Icon
                user={user}
                isOpenHamburger={isOpenHamburger}
                transitionClass={transitionClass}
            />

            {/* {!isOpenHamburger && (
                <div
                    className={spaceBetween(
                        c.levelCardContainer,
                        transitionClass,
                        level == null || isTooltipOpened
                            ? "opacity0"
                            : "opacity1"
                    )}
                >
                    {xpBoostRemainingSeconds > 0 ? (
                        <Card className={c.timerCard}>
                            <FormattedRemainingTime
                                remainingSeconds={xpBoostRemainingSeconds}
                            />
                        </Card>
                    ) : (
                        <Card className={c.levelCard}>Lv.{level}</Card>
                    )}
                </div>
            )} */}
        </>
    );
}
const useLevelStyles = makeStyles(theme => ({
    levelCard: {
        backgroundColor: theme.palette.grey[800],
        color: "white",
        position: "absolute",
        top: -5,
        padding: "0 5px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        fontWeight: "bold",
    },
    timerCard: {
        backgroundColor: theme.palette.grey[800],
        color: "white",
        position: "absolute",
        top: -5,
        padding: "0 5px",
        cursor: "pointer",
        whiteSpace: "nowrap",
    },
    levelCardContainer: {
        height: 0,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

function Icon({
    user,
    isOpenHamburger,
    transitionClass,
}: {
    user?: User;
    isOpenHamburger?: boolean;
    transitionClass: string;
}) {
    const c = useIconStyles();

    if (user?.avatarPath) {
        return (
            <Avatar
                className={spaceBetween(
                    isOpenHamburger ? c.imgAvatarForOpenHamburger : c.imgAvatar,
                    transitionClass
                )}
            >
                <img
                    src={user.avatarPath}
                    className={spaceBetween(
                        isOpenHamburger
                            ? c.imgAvatarForOpenHamburger
                            : c.imgAvatar,
                        transitionClass,
                        c.imgObjectFit
                    )}
                    alt={user.name}
                />
            </Avatar>
        );
    }
    return (
        <PersonIcon
            className={spaceBetween(
                isOpenHamburger ? c.iconForOpenHamburger : c.icon,
                transitionClass
            )}
        />
    );
}
const useIconStyles = makeStyles({
    imgAvatar: {
        width: 40,
        height: 40,
        cursor: "pointer",
    },
    imgAvatarForOpenHamburger: {
        width: 0,
        height: 0,
    },
    icon: {
        width: 40,
        height: 40,
        color: "white",
        cursor: "pointer",
    },
    iconForOpenHamburger: {
        width: 0,
        height: 0,
        color: "white",
    },
    imgObjectFit: { objectFit: "cover", objectPosition: "50% 50%" },
});
