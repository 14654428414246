import Card from "@material-ui/core/Card";
import { makeStyles, Theme } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useRef, useState } from "react";
import Container from "reactstrap/lib/Container";
import Navbar from "reactstrap/lib/Navbar";
import NavbarBrand from "reactstrap/lib/NavbarBrand";
import {
    changeAppState,
    getAppState,
    useAppState,
} from "../../../../common/appState";
import { appPublicImg } from "../../../../common/consts";
import { sleepAsync } from "../../../../common/functions";
import { useScreenSize } from "../../../../common/hooks/useScreenSize";
import { spaceBetween } from "../../../../common/util/Array/spaceBetween";
import { SuccessButton } from "../../../shared/Button/SuccessButton";
import { DarkLayer } from "../../../shared/DarkLayer";
import { Link } from "../../../shared/Link/LinkWithYouTube";
import { AccountType } from "../../../shared/User/types";
import { zIndex } from "../zIndex";
import { LoginIcon } from "./components/LoginIcon";
import "./style.css";

export default function NavMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLElement>(null);
    const headerHeight = useHeaderHeight(ref.current);
    const hideAppBar = useHideAppBar();
    const [user] = useAppState("user");

    useEffect(() => {
        const onScroll = () => {
            setIsOpen(false);
        };
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    const c = useNavMenuStyles({ hideAppBar, isOpen, headerHeight });

    return (
        <>
            <header ref={ref} className={c.header}>
                <Navbar
                    variant="pills"
                    className={spaceBetween(
                        "navbar-inverse navbar-expand-md navbar-toggleable-md border-bottom box-shadow mb-3",
                        c.navBar
                    )}
                >
                    <Container>
                        <NavbarBrand
                            tag={Link}
                            to="/"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            <div className={c.logoImgAndTitle}>
                                <img
                                    className={c.logoImg}
                                    src={`${appPublicImg}logo.png`}
                                />
                                <div className={c.title}>
                                    <span
                                        className={spaceBetween(
                                            "z-apps-title text-light large",
                                            c.titleContainer
                                        )}
                                    >
                                        One World
                                    </span>
                                    <span
                                        className={spaceBetween(
                                            "z-apps-title text-light small",
                                            c.titleContainer
                                        )}
                                    >
                                        Japanese Language Center INC.
                                    </span>
                                </div>
                            </div>
                        </NavbarBrand>
                        <Menus isOpen={isOpen} />
                    </Container>

                    {user &&
                        (user.accountType === AccountType.Admin ||
                            user.accountType === AccountType.Teacher) && (
                            <Card className={c.helpCard}>
                                <SuccessButton
                                    startIcon={<HelpIcon />}
                                    className={c.helpButton}
                                    size="small"
                                    onClick={() => {
                                        changeAppState("helpPanelState", {
                                            open: true,
                                        });
                                    }}
                                >
                                    Help
                                </SuccessButton>
                            </Card>
                        )}
                </Navbar>
            </header>
            <div className={c.spaceBelow} />
            <DarkLayer
                open={isOpen}
                onClick={() => {
                    setIsOpen(false);
                }}
                zIndex={zIndex.navMenu.smartphoneMenuOpenDarkLayer}
            />
        </>
    );
}
const topOverFlow = 50;
const useNavMenuStyles = makeStyles<
    Theme,
    { hideAppBar: boolean; isOpen: boolean; headerHeight: number }
>({
    titleContainer: {
        whiteSpace: "nowrap",
        fontWeight: "bold",
    },
    logoImgAndTitle: {
        display: "flex",
        alignItems: "center",
        gap: 15,
    },
    logoImg: {
        height: 45,
    },
    title: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        lineHeight: 1.4,
        position: "relative",
        bottom: 2,
    },
    header: ({ hideAppBar, isOpen }) => ({
        top: hideAppBar ? -(100 + topOverFlow) : -topOverFlow,
        zIndex: zIndex.navMenu.header(isOpen),
    }),
    navBar: {
        backgroundColor: "#222222",
        marginTop: topOverFlow,
        position: "relative",
    },
    spaceBelow: ({ headerHeight }) => ({
        height: headerHeight,
        marginBottom: 14,
    }),
    helpCard: {
        position: "absolute",
        right: 0,
        bottom: -32,
        padding: 2,
    },
    helpButton: {
        maxHeight: 26,
        minHeight: 26,
        fontSize: "small",
    },
});

function Menus({ isOpen }: { isOpen: boolean }) {
    const c = useMenusStyles();
    const { screenWidth } = useScreenSize();
    const isHamburger = screenWidth < 768;
    const iconMarginLeft = screenWidth / 30;

    return (
        <>
            <div className={c.iconsArea}>
                {isHamburger && (
                    <LoginIcon
                        containerStyle={{
                            marginLeft: `calc(${iconMarginLeft}px + 0.25rem)`, // Hamburger's right margin is 0.5rem,
                        }}
                        isOpenHamburger={isOpen && isHamburger}
                        transitionClass={"t500ms"}
                    />
                )}
            </div>
            {!isHamburger && (
                <LoginIcon
                    containerStyle={{
                        marginLeft: Math.min(iconMarginLeft, 25),
                    }}
                    transitionClass={"t1s"}
                />
            )}
        </>
    );
}
const useMenusStyles = makeStyles({
    iconsArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
});

function useHeaderHeight(headerElement: HTMLElement | null) {
    const [headerHeight, setHeaderHeight] = useAppState("headerHeight");
    const { screenWidth } = useScreenSize();
    useEffect(() => {
        if (headerElement) {
            setHeaderHeight(headerElement.clientHeight - (14 + topOverFlow));
        }
    }, [screenWidth, headerElement]);
    return headerHeight;
}

export function scrollToElement(
    element: HTMLElement | null,
    noSmooth?: boolean,
    headerShown?: boolean
) {
    if (headerShown) {
        const { headerHeight } = getAppState();
        const elementTop = element?.getBoundingClientRect()?.top || 0;

        window.scrollTo({
            top: window.scrollY + elementTop - headerHeight,
            behavior: noSmooth ? undefined : "smooth",
        });
        return;
    }

    hideHeaderTemporarily();
    element?.scrollIntoView(
        noSmooth
            ? true
            : {
                  behavior: "smooth",
              }
    );
}

export let hideHeaderTemporarily: () => void = () => {};

let previousScrollY = 0;
let isHidden = false;
let appBarTimer = 0;

function useHideAppBar() {
    const [hideAppBar, setHideAppBar] = useState(false);

    hideHeaderTemporarily = () => {
        setHideAppBar(true);
        isHidden = true;

        if (appBarTimer > 0) {
            clearTimeout(appBarTimer);
        }
        appBarTimer = window.setTimeout(() => {
            setHideAppBar(false);
            isHidden = false;
        }, 5000);
    };

    useEffect(() => {
        const scrollHandler = async () => {
            await sleepAsync(10); // In case where starting point's scrollY is 0

            const isRapidScroll = window.scrollY > previousScrollY + 500;
            previousScrollY = window.scrollY;

            if (window.scrollY <= 0) {
                setHideAppBar(false);
                return;
            }

            if (isHidden) {
                return;
            }

            if (!isRapidScroll) {
                return;
            }

            hideHeaderTemporarily();
        };

        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    return hideAppBar;
}
