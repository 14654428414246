import Card from "@material-ui/core/Card";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeAppState, useAppState } from "../../../../common/appState";
import { spaceBetween } from "../../../../common/util/Array/spaceBetween";
import { CollapseWithUnmount } from "../../../shared/Collapse/CollapseWithUnmount";
import { zIndex } from "../zIndex";

export default function Footer({ isFooterShown }: { isFooterShown: boolean }) {
    const { scrollingDown } = useScrollingDown();
    const c = useFooterStyles({ scrollingDown, isFooterShown });
    const [maintenanceRemainingMinutes] = useAppState(
        "maintenanceRemainingMinutes"
    );

    return (
        <>
            <footer className={c.footer}>
                <div className="center">
                    <div className={spaceBetween(c.container, "text-muted")}>
                        Copyright One World JLC. All rights reserved.{" "}
                        <Link
                            to=""
                            className={"inline-block"}
                            onClick={ev => {
                                ev.preventDefault();
                                changeAppState("termOfUsePanelState", {
                                    open: true,
                                });
                            }}
                        >
                            Terms of Use
                        </Link>
                    </div>
                </div>
            </footer>

            {maintenanceRemainingMinutes <= maxAlertTiming && (
                <MaintenanceAlert
                    maintenanceRemainingMinutes={maintenanceRemainingMinutes}
                />
            )}
        </>
    );
}
const useFooterStyles = makeStyles<
    Theme,
    { scrollingDown: boolean; isFooterShown: boolean }
>({
    container: {
        lineHeight: "16px", // "px" is necessary
    },
    footer: ({ scrollingDown, isFooterShown }) => ({
        bottom: isFooterShown ? (scrollingDown ? -26 : 0) : -61,
        transition: "all 500ms",
        position: "fixed",
        width: "100%",
        backgroundColor: "#eeeeee",
        paddingTop: 3,
        paddingBottom: 3,
        fontSize: "small",
        zIndex: zIndex.footer,
    }),
});

const html = document.getElementsByTagName("html")[0];
let oldScroll = html.scrollTop;
let timeoutId: NodeJS.Timeout;

function useScrollingDown() {
    const [scrollingDown, setScrollingDown] = useState(false);
    useEffect(() => {
        const onScroll = () => {
            if (oldScroll < html.scrollTop) {
                setScrollingDown(true);

                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setScrollingDown(false);
                }, 5000);
            } else {
                setScrollingDown(false);
            }
            oldScroll = html.scrollTop;
        };
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);
    return { scrollingDown };
}

const alertTiming = [1, 2, 3, 5, 10];
const maxAlertTiming = Math.max(...alertTiming);
function MaintenanceAlert({
    maintenanceRemainingMinutes,
}: {
    maintenanceRemainingMinutes: number;
}) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (
            alertTiming.includes(maintenanceRemainingMinutes) ||
            maintenanceRemainingMinutes < 1
        ) {
            setOpen(true);
        }
    }, [maintenanceRemainingMinutes]);

    const c = useMaintenanceAlertStyles();

    const explanation =
        "This will cause the website to be unavailable for a few minutes! I'm sorry for the inconvenience.";

    return (
        <CollapseWithUnmount in={open} className={c.collapse}>
            <Card className={c.card}>
                <div className={c.upperArea}>
                    <h2 className={c.title}>Server Maintenance Alert!</h2>
                    <div className={c.expandIconContainer}>
                        <ExpandMoreIcon
                            className={c.expandIcon}
                            onClick={() => {
                                setOpen(false);
                            }}
                        />
                    </div>
                </div>
                {maintenanceRemainingMinutes < 1 ? (
                    <div>
                        Server maintenance is now ongoing!
                        <br />
                        {explanation}
                    </div>
                ) : (
                    <div>
                        <span className={c.marginRight5}>
                            Server maintenance will be conducted
                        </span>{" "}
                        <span className={c.minutesContainer}>
                            {maintenanceRemainingMinutes} minute
                            {maintenanceRemainingMinutes > 1 ? "s" : ""} later!
                        </span>
                        <div className={c.marginTop10}>{explanation}</div>
                    </div>
                )}
            </Card>
        </CollapseWithUnmount>
    );
}
const useMaintenanceAlertStyles = makeStyles(theme => ({
    collapse: {
        bottom: 0,
        left: 0,
        position: "fixed",
        width: "100%",
        zIndex: zIndex.maintenanceAlert,
    },
    card: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        padding: 20,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    upperArea: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
        alignItems: "center",
    },
    title: { margin: 0 },
    expandIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    expandIcon: { height: 50, width: 50, cursor: "pointer" },
    minutesContainer: {
        fontSize: "x-large",
        whiteSpace: "nowrap",
    },
    marginRight5: { marginRight: 5 },
    marginTop10: { marginTop: 10 },
}));
