import Collapse, { CollapseProps } from "@material-ui/core/Collapse";
import { useEffect, useState } from "react";
import { sleepAsync } from "../../../common/functions";

export function CollapseWithUnmount({
    in: realtimeIn,
    children,
    onExited,
    timeBeforeOpenMS,
    ...rest
}: CollapseProps & { timeBeforeOpenMS?: number }) {
    const [delayedIn, setDelayedIn] = useState(realtimeIn);
    useEffect(() => {
        if (realtimeIn) {
            sleepAsync(timeBeforeOpenMS ?? 0).then(() => {
                setDelayedIn(true);
            });
        }
    }, [realtimeIn, timeBeforeOpenMS]);

    if (!realtimeIn && !delayedIn) {
        return null;
    }

    return (
        <Collapse
            in={realtimeIn && delayedIn}
            {...rest}
            onExited={node => {
                onExited?.(node);
                setDelayedIn(false);
            }}
        >
            {children}
        </Collapse>
    );
}
