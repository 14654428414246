import { entries } from "../../../common/util/Object/entries";
import { StudentLessonRelation } from "../../../types/LessonStatus";
import { InvalidLogs } from "../../../types/VideoLog";

export const AccountType = {
    Unapproved: 0,
    Student: 1,
    Teacher: 2,
    Admin: 3,
} as const;
export type AccountType = (typeof AccountType)[keyof typeof AccountType]; // 0:未承認ユーザー、1:生徒、2:先生、3:Admin

export const getAccountTypeKey = (accountType: AccountType) =>
    entries(AccountType).find(kv => kv[1] === accountType)?.[0];

export interface UserBase {
    userId: number;
    accountType: AccountType;
    facebookId: string;
    batchId: number; // 先生ユーザーとAdminユーザーは「-1」
    name: string;
    avatarPath: string;
    courseIds: number[];
}

export interface User extends UserBase {
    email: string;
}

export interface OtherUser extends UserBase {
    signUpMinutesAgo: number;
    lastUsageMinutesAgo: number;
    invalidLessonLogs: { [lessonId: number]: InvalidLogs };
    lessonStatus: StudentLessonRelation[];
    memo: string;
    deleted: boolean;
}

export interface UserForList extends UserBase {}

export interface UserForListWithDeleteStatus extends UserBase {
    deleted: boolean;
}

export interface UnapprovedUser extends UserBase {
    email: string;
    signUpMinutesAgo: number;
}

export interface UserForCsv extends UserBase {
    signUpMinutesAgo: number;
    lastUsageMinutesAgo: number;
    memo: string;
}
