import { AppToMount } from "../..";
import { ONE_WORLD_HOST } from "../../common/consts";

export const zApps: AppToMount = {
    key: "zApps",
    hostname: ONE_WORLD_HOST,
    getApp: async () => {
        const module = await import("./App");
        return module.App;
    },
};
